@font-face {
    font-family: 'pf_beausans_proregular';
    src: url('/fonts/pfbeausanspro-regular-webfont.eot');
    src: url('/fonts/pfbeausanspro-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/pfbeausanspro-regular-webfont.woff') format('woff'),
         url('/fonts/pfbeausanspro-regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'pf_beausans_problack';
    src: url('/fonts/pfbeausanspro-black-webfont.eot');
    src: url('/fonts/pfbeausanspro-black-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/pfbeausanspro-black-webfont.woff') format('woff'),
         url('/fonts/pfbeausanspro-black-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'pf_beausans_prothin';
    src: url('/fonts/pfbeausanspro-thin-webfont.eot');
    src: url('/fonts/pfbeausanspro-thin-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/pfbeausanspro-thin-webfont.woff') format('woff'),
         url('/fonts/pfbeausanspro-thin-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'pf_beausans_prolight';
    src: url('/fonts/pfbeausanspro-light-webfont.eot');
    src: url('/fonts/pfbeausanspro-light-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/pfbeausanspro-light-webfont.woff') format('woff'),
         url('/fonts/pfbeausanspro-light-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'pf_beausans_probbook';
    src: url('/fonts/pfbeausanspro-bbook-webfont.eot');
    src: url('/fonts/pfbeausanspro-bbook-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/pfbeausanspro-bbook-webfont.woff') format('woff'),
         url('/fonts/pfbeausanspro-bbook-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'pf_beausans_probold';
    src: url('/fonts/pfbeausanspro-bold-webfont.eot');
    src: url('/fonts/pfbeausanspro-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/pfbeausanspro-bold-webfont.woff') format('woff'),
         url('/fonts/pfbeausanspro-bold-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'pf_beausans_prosemibold';
    src: url('/fonts/pfbeausanspro-semibold-webfont.eot');
    src: url('/fonts/pfbeausanspro-semibold-webfont.eot?#iefix') format('embedded-opentype'),
         url('/fonts/pfbeausanspro-semibold-webfont.woff') format('woff'),
         url('/fonts/pfbeausanspro-semibold-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}