@import "utilities/reset";
@import "utilities/fonts";

html{
    height:100%;
    overflow-y:scroll;
}

body{
    background:transparent;
    font:normal 12px/18px Tahoma, Geneva, sans-serif;
    height:100%;
    margin:0px;
    min-height:100%;
    padding:0px;
}

img{
    border:0px;
}

video {
    display:block;
}

.clear{
    clear:both;
}
a {
    color: #4b4b4b;
    font-family: pf_beausans_prosemibold,sans-serif;
}

#pageWrapper{
    /*background:transparent url(/img/body_background.png);*/
    background-position:center top;
    margin:0px auto;
    min-height:100%;
    width:1000px;
}

.feedback{
    background:#60c660;
    display:block;
    height:239px;
    left:-254px;
    line-height:78px;
    position:fixed;
    top:0px;
    width:280px;
    z-index:500;
    border-radius: 0px 12px 12px 0px;
}

.feedback-items {
    height:239px;
    margin-right:26px;
}
.feedback-item {
    border-bottom:1px solid #40943e;
    border-top:1px solid #a1dd96;
    color:#fff;
    display:block;
    font-size:14px;
    font-weight:900;
    height:78px;
    padding-left:25px;
    text-decoration:none;
}
.feedback-item:first-child {
    border-top:none;
}
.feedback-item:last-child {
    border-bottom:none;
}
.feedback-icon {
    display:inline-block;
    vertical-align: middle;
    margin:0 5px 0 0;
    width:24px;
    height:24px;
    background:url(/img/icons_feedback.png) no-repeat 0 0;
}
.feedback-icon-email {
    background-position: 0 0;
}
.feedback-icon-comment {
    background-position: 0 -24px;
}
.feedback-btn {
    display:block;
    height: 18px;
    width: 240px;
    padding: 0;
    position: absolute;
    top: 109px;
    right: -77px;
    text-align: center;
    font-family: pf_beausans_prolight;
    letter-spacing: 3px;
    text-transform: uppercase;
    font-size: 12px;
    color:#fff;
    text-decoration:none;
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
}

#pageHeader{
    height:99px;
    position:relative;
}

#pageHeader .logo{
    margin:0 0 0 -15px;
}


#pageHeader .phones{
    color:#0f9b13;
    height:54px;
    font-family: 'pf_beausans_prosemibold', Tahoma, Geneva, sans-serif;
    line-height: 18px;
    left:240px;
    position:absolute;
    top:15px;
    width:540px;
    text-shadow:0 0 10px #fff, 0 0 10px #fff, 0 0 10px #fff, 0 0 10px #fff, 0 0 10px #fff, 0 0 10px #fff;
    text-decoration: none;
}

#pageHeader .phones a{
    text-decoration: none;
}

#pageHeader .name {
    font-size: 14px;
    font-family: 'pf_beausans_proregular';
    letter-spacing: -1px;
}
#pageHeader .prefix {
    font-size: 18px;
}
#pageHeader .phone {
    font-size: 23px;
}
#pageHeader #header-column-1 {
    width:165px;
    margin-left: 70px;
}
#pageHeader #header-column-2 {
    width:120px;
}
#pageHeader .column {
    display: inline-block;
    vertical-align: top;
    margin: 0 36px 0 0;
}
#pageHeader .column:last-child {
    margin: 0 -4px 0 0;
}

#pageHeader .linksNoAuth{
    font:13px/13px 'pf_beausans_prosemibold', Tahoma, Geneva, sans-serif;
    height:25px;
    right:0;
    line-height:20px;
    position:absolute;
    text-align:right;
    top:9px;
    width:179px;
}

#pageHeader .linksNoAuth a{
    border-bottom:1px dotted #53a329;
    color:#0f9b13;
    font-weight:900;
    text-decoration:none;
}

#pageHeader .linksNoAuth a:hover{
    border-bottom:1px solid #53a329;
}

#pageHeader .linksNoAuth #userLoginMenu{
    background:#ffffff;
    border:1px solid #eee;
    border-radius:5px 5px 5px 5px;
    box-shadow: 0px 0px 8px #888;
    display:block;
    padding:1px 3px 10px 30px;
    right:-4px;
    text-align:right;
    top:-2px;
    z-index: 11;
}

#pageHeader .linksNoAuth #userLoginMenu .subLink{
    color:#888;
}

/* navigations */
.navigations {
    width:1000px;
    height:43px;
    margin:0;
    position:relative;
    z-index:10;
    border-radius:10px 10px 0 0;
    background: #68cc6a; /* Old browsers */
    background: -moz-linear-gradient(top,  #68cc6a 0%, #78c277 50%, #4dae4d 51%, #3a993b 100%); /* FF3.6+ */
    background: -webkit-linear-gradient(top,  #68cc6a 0%,#78c277 50%,#4dae4d 51%,#3a993b 100%); /* Chrome10+,Safari5.1+ */
    background: -ms-linear-gradient(top,  #68cc6a 0%,#78c277 50%,#4dae4d 51%,#3a993b 100%); /* IE10+ */
    background: linear-gradient(to bottom,  #68cc6a 0%,#78c277 50%,#4dae4d 51%,#3a993b 100%); /* W3C */
}
.navigations > ul {width:110%;list-style:none;margin:0;padding:0;}
.navigations > ul > li {
    float:left;
    border-right:1px solid #68a369;
    border-left:1px solid #9ddb9c;
    position: relative;
}
.navigations > ul > li:first-child {
    border-left:none;
}
.navigations > ul > li:last-child {
    border-right:none;
}
.navigations > ul > li > a {display:block; height:43px; font:16px/43px 'pf_beausans_prosemibold', Tahoma, Geneva, sans-serif; color:#fff; text-decoration:none; text-shadow:0 -1px 1px #004f00; padding:0 8px;margin:0 14px;}
.navigations > ul > li:hover > a,
.navigations ul li .active { background: #eee;color:#004f00; text-shadow:none;}

.navigations .dropdown{
    list-style:none;
    box-shadow:0px 5px 8px #666666;
    display:none;
    position:absolute;
    left:14px;
    background:#eeeeee;
    line-height:27px;
    margin-right:0px;
    min-width:240px;
    padding:0px 0px 0px 19px;
}
.navigations ul li:hover .dropdown {
    display: block;
}
.navigations .dropdown a{color:#206f50;}

#pageContent{
    background:#ffffff;
    clear:both;
    color:#414141;
    padding:0px 16px 173px 16px;
    margin:0;
    width:968px;
    z-index:1;
}

/*#pageContent h3{
        color:#1ea501;
        font-family:Tahoma, Arial, Helvetica, sans-serif;
        font-size:30px;
        font-weight:normal;
        margin:0px 0px -10px 0px;
}

#pageContent h2{
        clear:both;
        color:#1ea501;
        font-family:Tahoma, Arial, Helvetica, sans-serif;
        font-size:20px;
        font-weight:normal;
}

#pageContent h1{
        color:#005a36;
        font-family:Tahoma, Arial, Helvetica, sans-serif;
        font-size:18px;
        font-weight:normal;
}*/

#pageContent .static_sidebar{
    float:right;
    line-height:1.4em;
    margin-top:-20px;
    padding:15px 5px;
    width:240px;
}

#pageContent .static_sidebar .social{
    background:url(/img/social_sprite.png) no-repeat;
    display:block;
    float:left;
    height:32px;
    margin-right:5px;
    width:32px;
}

#pageContent .static_sidebar .skype_link{
    display:block;
    font-size:10px;
    height:32px;
    margin:5px;
}

#pageContent .static_sidebar .social_link{
    font-size:10px;
    margin:5px;
}

#pageContent .mainNews{
    float:left;
    height:82px;
    overflow:hidden;
    width:300px;
    padding:0 10px;
    border-left:1px solid #c9c9c9;
}
#pageContent .mainNews:first-child {
    padding:0 10px 0 0;
    border:none;
}

#pageContent .mainNews .date{
    color:#1ea501;
    float:left;
    font:normal 14px/14px 'pf_beausans_problack', Arial, Helvetica, sans-serif;
    text-align:right;
    width:60px;
}

#pageContent .mainNews .date strong {display:block; font:normal 48px/48px 'pf_beausans_problack', Arial, Helvetica, sans-serif;}

#pageContent .mainNews .text{
    line-height:16px;
    margin-left:70px;
}

#pageContent .mainNews .text a{
    color:#1ea501;
}

#pageFooter{
    background:#e8e8e8;
    clear:both;
    height:153px;
    margin:-153px auto 0px auto;
    /*width:984px;*/

    .ya-share2 {
        position: absolute;
        top: 25px;
    }
}

#pageFooter .content{
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;;
    font-size:11px;
    margin:0px auto;
    width:967px;
}

#pageFooter .links{
    text-align:center;
}

#pageFooter .links a{
    color:#424443;
    margin:10px;
}

#pageFooter .icons{
    height:28px;
}

#pageFooter .icon-uds{
    height:20px;
    padding: 2px 0 2px 5px;
}

#pageFooter .logos{
    display: flex;
    justify-content: center;
    text-align:center;
}

#pageFooter .logos a{
    display: inline-block;
}


.submenu{
    background:#eeeeee;
    border-bottom:1px solid #bbbbbb;
    display:block;
    height:32px;
    margin-top:0px;
    margin-left:-16px;
    line-height:32px;
    padding:0px 18px;
    width:964px;
}
.submenu a{
    color:#015b37;
    margin:0px 10px;
}
.submenu .selected{
    background:#015b37;
    border-radius:5px;
    color:#ffffff;
    font-weight:900;
    margin:0px;
    padding:3px 18px;
    text-decoration:none;
}
.dialogue_border{
    background:rgba(240, 240, 240, 0.5);
    border-radius:10px;
    display:none;
    padding:10px;
}
.dialogue_title{
    background:#4865c3 url(/img/menu_background.png) repeat-x;
    background-position:bottom center;
    border-radius:5px 5px 0px 0px;
    color:white;
    font-family:Arial, Gadget, sans-serif;
    font-size:23px;
    line-height:35px;
    padding:0px 10px;
    text-shadow:gray 0px -1px 1px;
}
.dialogue_content{
    background:#ffffff;
    border-radius:0px 0px 5px 5px;
    font-family:Tahoma, Geneva, sans-serif;
    padding:15px 20px;
}
.dialogue_content .button{
    background:url(/img/dialogue_button.png) repeat-x;
    border:0px;
    border-radius:5px;
    color:white;
    cursor:pointer;
    font-size:14px;
    font-weight:900;
    height:38px;
    line-height:38px;
    padding:0px 15px;
    text-shadow:gray 0px -1px 1px;
    display: inline-block;
    text-decoration: none;
}
.dialogue_content .button:hover{
}
.textfield{
    border:1px solid #4865c3;
    border-radius:5px;
    color:#737373;
    font-size:14px;
    margin:5px 0px;
    line-height:30px;
    width:150px;
}
.dialogue_screen{
    background:rgba(100, 100, 100, 0.4);
    display:block;
    left:0px;
    bottom: 0;
    right: 0;
    position:fixed;
    top:0px;
    z-index:1000;
    overflow: auto;
}
.loader{
    background:#ffffff;
    border:1px solid #bbbbbb;
    border-radius:5px;
    box-shadow:#333333 0px 0px 15px;
    left:364px;
    overflow:hidden;
    padding:10px;
    position:absolute;
    text-align:center;
    top:268px;
    z-index:100;
}
.photoprint{
    box-shadow:#333333 0px 0px 15px;
    border:1px solid #bbbbbb;
    border-radius:5px;
    display:block;
    height:600px;
    margin:15px 10px 0px 10px;
    overflow:hidden;
    position:relative;
    width:947px;
}
.photoprint.polygraphy {
    height:640px;
}
.photoprint .cityselector{
    background:#ffffff;
    border:1px solid #bbbbbb;
    border-radius:5px;
    box-shadow:#333333 0px 0px 15px;
    display:none;
    left:244px;
    overflow:hidden;
    padding:10px;
    position:absolute;
    text-align:center;
    top:120px;
    z-index:100;
}
.photoprint .stepline{
    color:white;
}
.photoprint .stepline span{
    float:left;
    font-family:Arial;
    font-size:28px;
    margin-left: 8px;
}
.photoprint .stepline div{
    display:block;
    float:left;
    font-size:11px;
    height:45px;
    line-height:45px;
    margin:15px 16px;
    position:relative;
    text-align:center;
    width:200px;
}
.photoprint .steptile{
    background:#ffffff;
    display:block;
    height:510px;
    position:absolute;
    top:75px;
    width:896px;
}
.photoprint .sidebar{
    float:right;
    width:200px;
}
.photoprint .direction{
    background:transparent url(/img/li_direction.png) no-repeat;
    display:block;
    float:left;
    height:15px;
    margin-right:5px;
    margin-top:4px;
    width:15px;
}
.photoprint .orderarea{
    display:block;
    height:510px;
    position:absolute;
    overflow-y:scroll;
    width:680px;
}



.photoprint .order_image {
    background:url(/img/transparent.png);
    border:1px solid gray;
    margin:4px 9px 9px 4px;
    display:block;
    float:left;
    height:202px;
    width:150px;
    position: relative;
}

.photoprint .order_image .remove-icon, .view-icon {
    width: 16px;
    height: 16px;
    display: inline-block;
    margin: -2px;
}

.photoprint .order_image .remove-icon {
    background: url(/img/cancel.png);
    float: right;
}
.photoprint .order_image .cropping {
    background:#ccc;
    text-align: center;
    height: 20px;

}
.photoprint .order_image a {
    color: #333;
}

.photoprint .order_image .workarea {
    position:absolute;
    border:2px dashed #33f;
    top:0;
    left:0;
    width: 100px;
    height: 100px;
}
.photoprint .order_image .workarea-bg {
    position:absolute;
    border:2px dashed #fff;
    background:#fff;
    top:0;
    left:0;
    width: 100px;
    height: 100px;
}
.photoprint .order_image.select {
    border:5px solid #f4c626;
    margin:0 5px 5px 0;
}
.photoprint .order_image .params
{
    background:#ccc;
    color:#333;
    display:block;
    float:left;
    font-size:12px;
    height:32px;
    line-height:14px;
    left:0px;
    overflow:hidden;
    padding:0px 5px;
    width:140px;
}
.photoprint .order_image .image-wrapper {
    position: relative;
    width: 150px;
    height: 150px;
}
.photoprint .order_image .image {
    position: absolute;
}
.photoprint .order_image .params span
{
    cursor:pointer;
    text-decoration: underline;
}
.photoprint .format_select, .photoprint .paper_select, .photoprint .count_select
{
    background:rgba(255,255,255,0.7);
    color:black;
    display:none;
    height:140px;
    padding:5px;
    position:absolute;
    width:140px;
    z-index:216;
}




.photoprint .stepbuttons{
    bottom:0px;
    height:35px;
    left:710px;
    margin-bottom:16px;
    overflow:hidden;
    padding:0px;
    position:absolute;
    width:200px;
}
.photoprint .back{
    background:url(/img/button_back_next.png) no-repeat;
    background-position:bottom left;
    display:block;
    float:left;
    height:35px;
    width:100px;
}
.photoprint .next{
    background:url(/img/button_back_next.png) no-repeat;
    background-position:bottom right;
    display:block;
    float:left;
    height:35px;
    width:100px;
}
.photoprint .input{
    border:1px solid #4865c3;
    border-radius:5px;
    font-size:20px;
    margin-bottom:10px;
}
.photoprint #addressInfo{
    font-size:14px;
    line-height:20px;
}

.tabHolder{
    margin-top:20px;
}
.tabHolder .book_types{
    display: flex;
}
.tabHolder .header{
    border-bottom:1px solid #1ea501;
    border-radius:10px 10px 0px 0px;
    color:#d50100;
    cursor:pointer;
    float:left;
    font-family:Tahoma, Arial, Helvetica, sans-serif;
    font-size:27px;
    line-height:46px;
    text-align:center;
    width:483px;
    padding: 3px;
}
.tabHolder .active{
    border:1px solid #1ea501;
    border-bottom:none;
    border-radius:10px 10px 0px 0px;
    float:left;
    width:482px;
}
.tabHolder .content {
    clear:both;
    border:1px solid #1ea501;
    border-top:none;
    border-radius:0px 0px 10px 10px;
    padding:0 23px 23px 23px;
}
.tabHolder .parameters .title{
    color:#005935;
    font-size:1.1em;
    font-weight:900;
    text-transform:uppercase;
    width:40%;
}
.tabHolder .parameters td{
    vertical-align:top;
}
.tabHolder .parameters hr{
    border:0px;
    border-top:1px solid #669c85;
}
.tabHolder li{
    margin-bottom:10px;
}

.steps{
    display:block;
    height:96px;
}
.steps .step{
    color:#4aaa4a;
    cursor:pointer;
    display:block;
    float:left;
    font-size:18px;
    height:173px;
    margin:0px 4px;
    text-align:center;
    width:153px;
}
.steps .step .icon{
    background:url(/img/photobooks_step_icons.jpg);
    display:block;
    height:96px;
    margin:10px 30px;
    width:96px;
}
.steps .step .number{
    float:left;
    font-size:30px;
    font-weight:900;
    line-height:40px;
    margin:0px 5px 0px 15px;
    text-decoration:none;
}

.colorButton{
    background:#6bbd6b;
    border:1px solid #aaa;
    border-radius:3px;
    color:white;
    cursor:pointer;
    display:inline;
    font-family:Arial, Helvetica, sans-serif;
    font-size:16px;
    padding:4px 15px;
    text-shadow:0px 1px 1px #000;
}
.upload_image{
    background:url(/img/loading.gif) no-repeat;
    background-position:center center;
    display:block;
    float:left;
    height:190px;
    margin:5px;
    width:165px;
}
.upload_image .cancel
{
    cursor:pointer;
    float:right;
    height:16px;
    left:3px;
    position:relative;
    top:-3px;
    width:16px;
}

.album_preview{
    cursor:pointer;
    height:180px;
    float:left;
    margin:15px;
    position:relative;
    width:160px;
}


.album_preview .image{
    background-position:center center;
    border:2px solid white;
    height:158px;
    position:absolute;
    width:158px;
}

.album_preview .title{
    bottom:0px;
    position:absolute;
    width:230px;
}

.image_holder{
    background:url(/img/loading.gif) no-repeat;
    background-position:center center;
    float:left;
    margin:15px;
    width:160px;
}

.albumConfirm{
    background:white;
    border:1px solid #888;
    border-radius:0px 0px 5px 5px;
    box-shadow:0px 3px 8px #888;
    font-size:16px;
    height:48px;
    left:260px;
    line-height:48px;
    padding:0px 15px;
    position:absolute;
    top:-1px;
    width:480px;
    z-index:2;
}

.albumProcess{
    background:white;
    border:1px solid #888;
    border-radius:5px 5px 5px 5px;
    box-shadow:0px 3px 8px #888;
    font-size:16px;
    height:48px;
    left:45%;
    line-height:48px;
    padding:0px 15px;
    position:fixed;
    top:49%;
    z-index:200;
}

.productSelect{
    border:1px solid #888;
    border-radius:5px 5px 5px 5px;
    box-shadow:0px 3px 8px #888;
    position:relative;
}

.accordeon .tab{
    background:#3b983b url(/img/menu_background.png);
    background-position:center center;
    border-bottom:1px solid #555;
    border-top:1px solid #aaa;
    color:white;
    cursor:pointer;
    font-family:Verdana, Helvetica, sans-serif;
    font-size:16px;
    padding:4px 15px;
    text-shadow:0px -1px 1px #000;
}

.accordeon .active{
    background:url(/img/html_background_main.png);
}

.accordeon .tab_content{
    background:#fff;
    display:none;
    height:428px;
    overflow:hidden;
    position:relative;
}

.accordeon .tab_content .templatethumb{
    background:url(/img/transparent.png);
    border:1px solid gray;
    cursor:pointer;
    vertical-align: top;
    margin: 3px;
    display: inline-block;
    text-decoration: none;
    color:#00dd00;
    position: relative;
}

.accordeon .tab_content .templatethumb img {
    display: block;
}

.accordeon .tab_content .templatethumb span {
    position: absolute;
    right: 0;
    top: 0;
    display: none;
}

.accordeon .tab_content .templatethumb.active span {
    display: block;
}

.accordeon .tab_content .fonts_selector{
    background:url(/fonts/font1.png) no-repeat;
    border:1px solid #8e8e8e;
    border-radius:5px 5px 5px 5px;
    box-shadow:2px 2px 2px 1px rgba(192, 192, 192, 1);
    display:block;
    height:30px;
    margin:5px 10px 0px 10px;
    overflow:hidden;
    width:181px;
}

.accordeon .tab_content .fonts_selector .arrow{
    background:url(/img/comboarrow_sprite.png) no-repeat;
    display:block;
    float:right;
    height:22px;
    margin:5px 5px 5px 0px;
    width:22px;
}

.accordeon .tab_content .fonts_dropdown{
    background:white;
    border:1px solid #8e8e8e;
    border-radius:0px 0px 5px 5px;
    box-shadow: 0px 2px 2px 1px rgba(192, 192, 192, 1);
    display:none;
    height:150px;
    left:10px;
    overflow-x:hidden;
    overflow-y:scroll;
    position:absolute;
    top:36px;
    width:201px;
    z-index:2;
}

.accordeon .tab_content .fonts_dropdown img{
    margin:5px;
}

.accordeon .tab_content .fonts_dropdown img:hover{
    border:2px solid #3b983b;
    margin:3px;
}

.accordeon .userText{
    height:150px;
    max-height:150px;
    max-width:200px;
    width:180px;
}

#bigBanner{
    position:relative;
}

.bannerLink{
    background: #bbb;
    border-radius: 5px;
    bottom: 10px;
    box-shadow: 1px 1px 3px #000, -1px -1px 1px #fff;
    display: block;
    height:20px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -o-border-radius: 5px;
    position:absolute;
    width:20px;
}

#bannerPause{
    background:black;
    color:white;
    font-size:12px;
    font-weight:900;
    height:18px;
    padding-top:2px;
    text-align:center;
    text-decoration:none;
}

#bannerLink1{
    background:green;
}

#bannerLink2{
    background:yellow;
}

#bannerLink3{
    background:red;
}

#bannerLink4{
    background:blue;
}

/* slider */
.slider {position:relative; margin-left:0px; margin-left:-16px; margin-bottom:14px;width:1000px;}
.slider .slides_container {overflow:hidden; position:relative; display:block;width:1000px;}
.slider .main_banner {position:relative;width:1000px;height:300px;}
.slider .main_banner_content {position:absolute;width:350px;left:600px;top:60px;}
.slider .main_banner_text {font:32px/40px pf_beausans_prolight;margin:0 0 35px;}

.slider .arrow {position:absolute; top:120px; width:38px; height:38px; display:block; z-index:101; background: url(/img/slider-arrows.png) no-repeat;}
.slider .arrow.prev {left:-22px;}
.slider .arrow.next {right:-22px; background-position:-38px 0;}

h1 {padding:0; font-size:18px; line-height:18px; color:#1ea500; font-weight:normal; font-family:'pf_beausans_prolight', Tahoma, Geneva, sans-serif;margin:0.5em 0 1em;}
h2 {padding:0; font-size:24px; line-height:24px; color:#1ea500; font-weight:normal; font-family:'pf_beausans_prolight', Tahoma, Geneva, sans-serif;margin:0.5em 0 1em;}
h3 {padding:0; font-size:36px; line-height:40px; color:#1ea500; font-weight:normal; font-family:'pf_beausans_prolight', Tahoma, Geneva, sans-serif;margin:0.5em 0 1em;}
h4 {padding:0; font-size:16px; line-height:18px; color:#2c2c2c; font-weight:bold;margin:0.5em 0 1em;}
h5 {padding:0; font-size:14px; line-height:16px; color:#2c2c2c; font-weight:bold;margin:0.5em 0 1em;}
p { padding:0; line-height:16px;margin:0 0 1em;}

.small_banners {
    width: 1000px;
    margin:0 0 0 -16px;
}
.small_banner {
    display:inline-block;
    vertical-align: top;
    margin:0 -4px 0 17px;
    height:180px;
    width:259px;
    border-radius:5px;
    color:#fff;
    font:16px/20px pf_beausans_probold;
    text-decoration:none;
    text-align:center;
    text-shadow:0 -1px #146d14;
    line-height:54px;
}
.small_banner:first-child {
    margin:0 -4px 0 0;
}
.small_banner .small_banner_img {
    display:block;
    height:126px;
    border-radius: 5px 5px 0 0;
    background-image:url(/img/mainpage/sb_sprite.jpg);
}
.small_banner .small_banner_title {
    display:block;
    height:54px;
    font-size: 20px;
    border-radius: 0 0 5px 5px;
    background-image: linear-gradient(top, #64cb64 0%,#3a973a 100%);
    background-image: -webkit-linear-gradient(top, #64cb64 0%,#3a973a 100%);
}
#small_banner_calculator {
    width:173px;
    line-height:27px;
    background-image: linear-gradient(top, #64cb64 0%,#3a973a 100%);
    background-image: -webkit-linear-gradient(top, #64cb64 0%,#3a973a 100%);
}
#small_banner_calculator .small_banner_img {
    background-image:url(/img/mainpage/sb_calc.png);
}
#small_banner_calculator .small_banner_title {
    background-image:none;
}
#small_banner_constructor .small_banner_img {
    background-position:0 0;
}
#small_banner_photoprint .small_banner_img {
    background-position:-259px 0;
}
#small_banner_souvenirs .small_banner_img {
    background-position:-518px 0;
}

#sidebar {
    padding: 30px 0 0 15px;
}

.side_banners {
    margin:80px 0 0;
}
.side_banners .banners {
    position: relative;
    height:368px;
    overflow: hidden;
    margin: 10px auto;
    width: 240px;
}
.side_banners .banners ul {
    margin:0;
    padding:0;
    list-style:none;
    position: absolute;
    top:0;
}
#sb-bottom, #sb-top {
    width:25px;
    height:20px;
    display: block;
    background: url('/img/sb_nav.png');
    left:107px;
    margin: 0 auto;
}
#sb-bottom {
    background-position: 0 -20px;
}
.hide {
    display: none;
}
/*-------*/
#screen {
    background: transparent url(/img/alpha_dark_75.png);
    display: block;
    height: 100%;
    left: 0px;
    position: fixed;
    text-align: center;
    top: 0px;
    width: 100%;
    z-index: 149;
}
#screen_box {
    border:3px solid white;
    display:none;
    margin:0px auto;
    position:absolute;
    z-index:150;
    overflow:hidden;
    background:#fff;
}
#screen_panel {
    background: #fff;
    width: 200px;
    padding: 20px;
    border-radius: 20px;
    position: absolute;
    color:#003299;
    font-size: 18px;
    display: none;
}
.screen_option {
    text-align: left;
    padding: 5px;
}
.screen_option span {
    cursor: pointer;
    padding:5px;
}
.screen_option.active span {
    color:#fff;
    background: #003299;
    border-radius: 5px;
}
.right {
    float:right;
}
.left {
    float: left;
}

.uploadphotos-loader {
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
    right: 0;
    bottom: 0;
    background: #fff;
    z-index: 1;
    padding: 150px 0 0 0;
}
.loader-progress {
    height:30px;
    line-height:30px;
    width: 500px;
    margin: 10px auto;
    border-bottom: 1px solid #555;
    border-top: 1px solid #aaa;
    border-radius: 5px;
    position:relative;
    overflow: hidden;
}
.loader-progress-bg {
    height:30px;
    line-height:30px;
    display:inline-block;
    width:0%;
    background: #3b983b url(/img/menu_background.png);
    position:absolute;
    z-index:-1;
    top:0;
    left:0;
}

.button2 {
    outline:none;
    border:none;
    cursor:pointer;
    color:#fff;
    border-radius:5px;
    text-shadow:0px -1px #4b4b4b;
    line-height:20px;
    font-size:16px;
    font-family: pf_beausans_prolight;
    padding:10px 25px;
    background-color: #64cb64;
    background-image: linear-gradient(top, #64cb64 0%,#3a973a 100%);
    background-image: -webkit-linear-gradient(top, #64cb64 0%,#3a973a 100%);
    text-decoration:none;
}
.button2:hover {
    background-color: #74db74;
    background-image: linear-gradient(top, #74db74 0%,#4aa74a 100%);
    background-image: -webkit-linear-gradient(top, #74db74 0%,#4aa74a 100%);
}

.button3 {
    outline:none;
    border:none;
    cursor:pointer;
    color:#fff;
    border-radius:5px;
    text-shadow:0px -1px #4b4b4b;
    line-height:20px;
    font-size:16px;
    font-family: pf_beausans_prolight;
    padding:10px 25px;
    background-color: #2ad3d5;
    background-image: linear-gradient(top, #2ad3d5 0%,#02979d 100%);
    background-image: -webkit-linear-gradient(top, #2ad3d5 0%,#02979d 100%);
    text-decoration:none;
}
.button3:hover {
    background-color: #3ae3e5;
    background-image: linear-gradient(top, #3ae3e5 0%,#12a7ad 100%);
    background-image: -webkit-linear-gradient(top, #3ae3e5 0%,#12a7ad 100%);
}

/* partner banners on main page*/
.partner_banners {
    position: relative;
    margin:0 79px;
}
.partner_banners .arrow {
    position:absolute;
    top:41px;
    width:38px;
    height:38px;
    display:block;
    z-index:101;
    background: url(/img/slider-arrows.png) no-repeat;
}
.partner_banners .arrow.prev {left:-22px;}
.partner_banners .arrow.next {right:-22px; background-position:-38px 0;}
.partner_banners .slides_container {
    padding: 0;
    margin: 0;
    margin:0 30px;
}
.partner_banner {
    display:inline-block;
    margin:0 -4px 0 0;
    vertical-align: top;
    width:750px;
}
.partner_banner a {
    position:relative;
    width:240px;
    height:120px;
    display:inline-block;
    margin:0 -4px 0 0;
    vertical-align: top;
    padding:0 10px 0 0;
    background-repeat: no-repeat;
}

.require {
    color:#f41841;
}
.input-wrapper {
    position: relative;
}
.input{
    border:1px solid #e0e0e0;
    box-shadow: inset 0px 7px 20px #f5f6f7;
    padding: 11px 15px;
    margin: 0 0 15px;
    width:150px;
}
.input:focus,
.input:hover {
    outline:none;
    border: 1px solid #f2c641;
    box-shadow:0 0 2px #f2c641;
}
